:root {
  --jet: #2f2f2f;
  --raisinBlack: #1a1b25;
  --white: #fcfcfc;
  --dmWhite: #cccccc;
  --hotPink: #d90368;
  --turquoise: #55d6c2;
  --purple: #775576;
  --lightBlue: #ADD8E6;

  --pageBackground: var(--white);
  --imageBorder: var(--hotPink);
  --projectBorder: var(--turquoise);
  --primaryButton: var(--turquoise);
  --socialIcons: var(--jet);
  --secondaryButton: var(--purple);
  --buttonHover: var(--hotPink);
  --paragraphFont: var(--raisinBlack);
  --primaryHeading: var(--hotPink);
  --secondaryHeading: var(--hotPink);
  --linkVisited: var(--raisinBlack);
  --aboutContactBackground: var(--purple);
  --toggleUnchecked: var(--white);
  --toggleChecked: var(--turquoise);
  --projectBackground1: var(--lightBlue);

  
}


/* Dark Mode */
.dm{
  --pageBackground: var(--jet);
  --border: var(--turquoise);
  --primaryButton: var(--purple);
  --paragraphFont: var(--dmWhite);
  --socialIcons: var(--dmWhite);
  --secondaryButton: var(--turquoise);
  --aboutContactBackground: var(--turquoise);

} 

.dm button {
  filter: drop-shadow(0 1px 0.2rem var(--white));
}
.dm button:hover {
  filter: drop-shadow(0 1px 0.2rem var(--white));
}
.dm button p {
  color: var(--white);
}

.dm img {
  filter: grayscale(10%);
  opacity: 85%; 
}

.dm .social-icons a {
  color: var(--dmWhite);
}

.dm #navbar a {
  color: var(--dmWhite);
}

.dm #about .contact p{
  color: var(--raisinBlack);
}

.dm #about .social-icons .fab {
  color: var(--raisinBlack);
}

.dm #about .contact button p {
  color: var(--dmWhite)
}

.dm .code-btn p {
  color: var(--raisinBlack);
}

.dm .code-btn:hover p {
  color: var(--dmWhite);
}

/* Light Mode and Layout */
* {
  margin: 0; 
  padding: 0;
  box-sizing: border-box;
}

h1 {
  padding-bottom: 10px;
  font-size: 36px;
  font-family: "Playfair Display", serif;
  color: var(--primaryHeading)
}

h2 {
  color: var(--turquoise);
}

p {
  padding-bottom: 20px;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: var(--paragraphFont);
}

button {
  border-style: none;
  padding: 10px;
  border-radius: 5px;
  width: 150px;
  text-align: center;
  margin: 10px 0 10px 0;
  font-weight: bold;
  background-color: var(--primaryButton);
  filter: drop-shadow(0 1px 0.1rem var(--jet));
}

button:hover{
  filter: drop-shadow(0 1px 0.1rem var(--jet)); 
  background-color: var(--buttonHover); 
}

button:hover p {
  color: var(--white);
}

.button p {
  font-size: 14px;
  padding: 0;
  color: var(--raisinBlack);
}

.App {
  text-align: center;
  min-width: 280px;
  width: '100%';
  min-height: 100vh;
  background-color: var(--pageBackground);


}

.font-playfair {
  font-family: "Playfair Display", serif;
  font-weight: bold;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.social-icons a:visited {
  font-weight: bold;
}

/* NAVBAR STYLES */
#navbar {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  height: 6vh;
  width: 100%;
  position: fixed;
  background-color: var(--pageBackground);
  z-index: 2; 
}

#navbar .logo-container a {
  font-size: 36px;
}

#navbar .nav-links {
  display: flex;
  flex-direction: row;
  width: 25%;
  justify-content: space-between;
  font-size: 16px;
  text-decoration: none;
}

#navbar a {
  text-decoration: none;
  color: var(--raisinBlack);
}

#navbar a:visited {
  text-decoration: none;
  font-weight: bold;
}

#navbar a:hover, a:active {
  font-weight: bold;
  text-decoration: underline;
}

@media screen and (width > 1200px) {
  #navbar .nav-links {
    width: 15%;
  }
}

@media screen and (max-width: 720px) {
  #navbar {
    flex-direction: column;
    height: 10vh;
  }

  #navbar .nav-links {
    width: 50%;
    justify-content: space-between;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 550px ) {
  #navbar {
    flex-direction: column;
    height: 20vh;
  }

  #navbar .nav-links {
    flex-direction: column;
    width: 50%;
    padding-bottom: 10px;
  }
  #navbar .nav-links a {
    padding-bottom: 10px;
  }
  #navbar p {
    margin-top: 10px;
  }
}

@media screen and (max-width: 375px ) {
  #navbar {
    flex-direction: column;
    height: 20vh;
  }

  #navbar .nav-links {
    flex-direction: column;
    width: 50%;
    padding-bottom: 10px;
  }
  #navbar p {
    margin-top: 10px;
  }
}


/* HOMEPAGE STYLES */
#homepage {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden; 
  justify-content: center;
  padding: 110px 10px 110px 10px;
  align-self: center;
  min-width: 375px;

}

#homepage h1 {
  font-size: 36px;
}

#homepage .container-1 {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#homepage .container-1 .oh-hey p {
  padding-bottom: 0px;
}
#homepage .intro-text {
  text-align: left;
  width: 600px;
}

#homepage .intro-photo {
  overflow: visible;
  width: 600px;
  justify-self: flex-end;
  display: inline-block;
}

#homepage .photo {
  width: 40%;
  border-style: solid;
  border-width: 5px;
  border-radius: 30%;
  z-index: 10;
  border-color: var(--imageBorder);
}

#homepage .contact-links{
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
}

#homepage .button p {
  font-size: 14px;
  padding: 0;
}


#homepage .social-icons {
  width: 30%;
  display: flex;
  justify-content: space-around;
  font-size: 36px;
}

#homepage .social-icons a:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 900px) {
  #homepage {
    padding-top: 180px;
  }

  #homepage .photo {
    width: 55%;
    border-style: solid;
    border-width: 5px;
  }

  #homepage .contact-links{
    flex-direction: column;
  }

  #homepage .social-icons {
    width: 100%;
    justify-content: space-around;
    margin-top: 10px;
    font-size: 30px;
  }
}


@media screen and (max-width: 720px) {
  #homepage .photo {
    width: 50%;
  }
}

@media screen and (max-width: 550px ) {
  #homepage .container-1 {
    flex-direction: column;
    justify-content: center;
  
  }

  #homepage .intro-text {
    width: 75%;
    text-align: center;
    padding: 10px;
  }

  #homepage .photo {
    width: 50%;
    border-style: solid;
    border-width: 5px;
  }

  #homepage .social-icons {
    width: 40%;
    justify-content: space-around;
    margin-top: 10px;
    font-size: 36px;
  }
}

/* ABOUT PAGE STYLES */
#about {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  overflow: auto;
  padding-bottom: 100px;
  padding-top: 6vh;
}


#about .about-me {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

#about .about-text {
  width: 55%;
  justify-content: center;
  margin-left: 50px;
}

#about .about-text h1{
  font-size: 36px;
  text-align: left;
}

#about .about-text p {
  font-size: 18px;
  text-align: left;
}
#about .button p {
  padding: 0px;
  font-size: 14px;
  text-align: center;
}

#about .about-photo {
  width: 45%;
  display: flex;
  align-items: center;
}

#about .about-photo img {
  margin: auto;
  border-style: solid;
  border-width: 5px;
  border-radius: 30%;
  border-color: var(--imageBorder);
}

#about .skills {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#about .skills h2 {
  font-size: 36px;
}

#about .skills-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 70%;
}

#about .fab, .fas, .far{
  font-size: 36px;
  margin: 0 10px 0 10px;
  color: var(--socialIcons);
}

#about .skill-icon {
  font-size: 20px;
  text-align: center;
  color: var(--socialIcons);
}

#about .icon-container {
  padding: 10px;
}

#about .contact {
  width: 100%;
  background-color: var(--aboutContactBackground);
  color: var(--white);
}

#about .contact-text {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 75%;
  margin-top: 10px;
  padding: 40px 0 20px 0;
}

#about .contact p{
  color: var(--white);
}

#about .contact-links{
  display: flex;
  width: 50%;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  margin: auto;
  line-height: 50%;
}
#about .contact button {
  background-color: var(--primaryButton);
  box-shadow: 0 1.5px 1px var(--jet);
}

#about .contact button p {
  color: var(--raisinBlack)
}

#about .contact button:hover{
  box-shadow: 0 1.5px 1px var(--jet);
  background-color: var(--buttonHover); 
}


#about .social-icons {
  width: 20%;
  display: flex;
  justify-content: space-around;
  font-size: 36px;
}
#about .social-icons .fab {
  color: var(--socialIcons);
  transform: scale(1.1);
}

#about .social-icons .fab:hover {
  transform: scale(1.1);
}

#about .social-icons a:hover {
  transform: scale(1.1);
}


@media screen and (max-width: 900px) {
  #about .about-text {
    width: 45%;
    justify-content: center;
    margin-left: 20px;
  }
  #about .about-photo img{
    width: 55%;
  }
  .skills-icons {
    width: 100%;
  }
  #about .contact-links{
    flex-direction: column;
  }
  #about .social-icons {
    width: 30%;
    font-size: 30px;
    margin: 10px 0 10px 0;
  }
}


@media screen and (max-width: 720px) {
  #about{
    padding-top: 9vh;
  }
  #about .about-text h1{
    font-size: 30px;
  }
  #about .about-text {
    text-align: center;
  }
  
  #about .about-text p {
    font-size: 16px;
  }
  #about .about-photo {
    width: 80%;
  }
  .skills-icons {
    width: 100%;
  }
  .skills h2{
    font-size: 30px;
  }
  
}

@media screen and (max-width: 550px ) {

  #about{
    padding-top: 20vh;
  }
  #about .about-me {
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
    margin-bottom: 20px;
    padding: 5px 15px 0px 15px;
    justify-content: center;
    
  }
  #about .about-text {
    width: 100%;
    margin: 0 ;
  }
  #about .about-text h1,p {
    text-align: center;
  }
  #about .about-photo {
    display: flex;
    align-self: center;
    width: 80%;
  }
}

@media screen and (max-width: 375px ) {
  .fab, .fas, .far{
    font-size: 25px;
    margin: 0 5px 0 5px;
  }
  .skill-icon{
    font-size: 16px;
  }
  .icon-container{
    padding: 5px;
  }
}


/* PROJECTS MAIN PAGE STYLES */
#projects {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  width: 100%;
  padding-bottom: 100px;
  padding-top: 6vh;
}

#projects .project-heading p {
  font-size: 20px;
  padding-bottom: 2px;
}

#projects .card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

#projects .card-subtitle {
  display: flex;
  width: 100%;
  margin-left: 20px;
}

#projects .card {
  height: 350px;
  width: 400px;
  margin: 10px;
  border-radius:10px;
  justify-content: center;
}


#projects img {
  border-radius:10px;
}

#projects img:hover {
  border-style: solid;
  border-width: 10px;
  border-radius:10px;
  border-color: var(--projectBorder);
}



@media screen and (max-width: 900px) {
  #projects .card-subtitle {
    display: flex;
    width: 100%;
    margin-top: 25px;
    justify-content: center;
  }
  #projects .card {
    margin: 10px;
    width: 350px;
    height: 306px;
    justify-content: center;
  }  
}

@media screen and (max-width: 720px) {
  #projects  {
    padding-top: 15vh;
  }
    
}

@media screen and (max-width: 550px) {
  #projects  {
    padding-top: 22vh;
  }
    
}

@media screen and (max-width: 375px ) {
  #projects .card {
    margin: 5px;
    width: 300px;
    height: 263px;
    justify-content: center;
  }
}

/* PROJECT DETAIL PAGE STYLES */
.project-detail-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin:auto;
  padding: 150px 0 100px 0;
  max-width: 1200px;
}
.project-detail-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 50%;
}
.tech-stack-list{
  font-style: italic;
  font-size: 12px;
  word-spacing: 5px;
  margin-left: 10px;
}
.tech-stack-list span{
  font-style: normal;
}

.project-name {
  margin-left: 10px;

}

.project-small-description{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0 10px 0 40px;
  font-weight: bold;
}

.project-long-description{
  display: flex;
  flex-direction: column;
  text-align: left;
  display: flex;
  margin: 0 10px 0 70px;
}

.project-long-description a {
  text-decoration: underline;
  color: var(--paragraphFont)
}
.project-button-container{
  display: flex;
  flex-direction: row;
  width: 70%;
  margin-left: 70px;
  justify-content: space-between;
}

.code-btn {
  background-color: var(--secondaryButton);
  color: var(--white);
}

.code-btn p {
  color: var(--white);
}

.project-image-container{
  width: 50%;
  background-color: var(--projectBackground1)
}

.project-image-container img{
  width: 80%;
}

@media screen and (max-width: 900px) {
  .project-button-container{
    width: 80%;
    margin-left: 70px;
    justify-content: space-between;
  }
}


@media screen and (max-width: 720px) {
  .project-detail-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
  }
  .project-detail-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
  }
  .tech-stack-list{
    font-style: italic;
    font-size: 12px;
    word-spacing: 5px;
    margin: 0 auto;
  }
  .tech-stack-list span{
    font-style: normal;
  }
  
  .project-small-description{
    text-align: center;
    margin: 0 auto;

  }
  
  .project-long-description{
    text-align: center;
    margin: 0 auto;
    padding: 0 10px;
    width: 100%;
  
  }
  .project-button-container{
    width: 70%;
    margin: auto;
    justify-content: space-evenly;
  }
  
  .project-image-container{
    width: 100%;
    height: 500px;
  }

}

@media screen and (max-width: 550px ) {
  .project-detail-container {
    padding-top: 200px;
  }
  .project-button-container{
    width: 100%;
    flex-direction: column;
  }
}


/* FOOTER STYLES */
#footer {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  height: 100px;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--raisinBlack);
  color: var(--white);
}

#footer .footer-text {
  width: 300px;
}

#footer p{
  line-height: 50px;
  color: var(--white);
  padding-bottom: 4px;
}

#footer .toggle{
  display: flex;
  width: 250px;
  height: 34px;
  justify-content: space-evenly;
  
}

#footer .toggle p{
  line-height: 34px;
}

#footer .switch {
  position: relative;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
#footer .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--toggleUnchecked);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--toggleChecked);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--toggleChecked);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (max-width: 720px) {
  #footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100px;
    padding-bottom: 20px;
  }
  #footer .footer-text {
    width: 100%;
  }
  #footer .footer-text p {
    padding-bottom: 0px;
    line-height: 75px;
    font-size: 16px;
  }
  #footer .switch {
    display: flex;
    position: relative;
  }
}

